import './portfolio.css'
import B_THESIS from '../../assets/bachelor_thesis.png'
import M_THESIS from '../../assets/master_thesis.png'
import APP from '../../assets/app.png'
import CC_certificate from '../../assets/CC_certificate.png'

const Portfolio = () => {
    return (
        <section id='portfolio'>
            <h5>My Recent Works</h5>
            <h2>Portfolio</h2>
            <div className='container portfolio__container'>

                <article className='portfolio__item'>
                    <div className="portfolio__item-image">
                        <img src={APP} alt="Tracker for NFC Tags" />
                    </div>
                    <h3>Tracker for NFC Tags</h3>
                    <p>I developed an application for <a href='https://www.zwickroell.com/'>ZwickRoell</a> to track equipment movements in a database. Built using SwiftUI with MongoDB as the backend and APIs developed in TypeScript.</p>
                    <div className="portfolio__item-cta">
                        <a href="https://youtube.com/shorts/2jQkTyIamDg?si=PUysXRjTH6J8qxMY" className='btn'>YouTube</a>
                    </div>
                </article>

                <article className='portfolio__item'>
                    <div className="portfolio__item-image">
                        <img src={CC_certificate} alt="CyberChallenge Certificate" />
                    </div>
                    <h3>CyberChallenge</h3>
                    <p>
                        I first participated as a competitor and later as a tutor in the CyberChallenge Program, which aims to identify, attract, train, recruit, and place the next generation of cybersecurity professionals into the workforce.

                    </p>
                    <div className="portfolio__item-cta">
                        <a href="https://cyberchallenge.it/" className='btn'>Go To The Website</a>
                    </div>
                </article>

                <article className='portfolio__item'>
                    <div className="portfolio__item-image">
                        <img src={M_THESIS} alt="My Thesis" />
                    </div>
                    <h3>Master's thesis</h3>
                    <p>Exploring the Inner Workings of (anti)cheat Tools</p>
                    <div className="portfolio__item-cta">
                        <a href="https://drive.google.com/file/d/1YHEkbXW8ALsQ-rM6gdEsxuUaZHz1Bx81/view?usp=drivesdk" className='btn'>Google Drive</a>
                    </div>
                </article>

                <article className='portfolio__item'>
                    <div className="portfolio__item-image">
                        <img src={B_THESIS} alt="My Thesis" />
                    </div>
                    <h3>Bachelor's thesis</h3>
                    <p>Potenzialità e rischi delle reti generative</p>
                    <div className="portfolio__item-cta">
                        <a href="https://drive.google.com/file/d/1Bz2EuKLFnRKjzoslJdkqMYNWUzNyWagE/view?usp=sharing" className='btn'>Google Drive</a>
                    </div>
                </article>

                <article className='portfolio__item'>
                    <h3>Lecture Notes</h3>
                    <p>My collection of university lecture notes.</p>
                    <div className="portfolio__item-cta">
                        {/* get the current url and append /notes to it */}
                        <a href={new URL('/notes', window.location.href).toString()} className='btn'>Notes</a>
                    </div>
                </article>
                
            </div>
        </section>
    )
}

export default Portfolio
